import { ForwardedRef, forwardRef } from "react"
import { twMerge } from "tailwind-merge"
import Image from 'next/image'

export type IconProps = {
  src: string,
  alt: string,
  width?: number,
  height?: number,
  className?: string | undefined
}

export const Icon = forwardRef(
  function Icon(props: IconProps, ref: ForwardedRef<HTMLImageElement>) {
    const {
      src,
      width = 40,
      height = 40,
      alt,
      className,
      ...inputProps
    } = props
    return (
      <Image
        ref={ref}
        alt={alt}
        width={width}
        height={height}
        src={src}
        className={twMerge(
          className
        )}
        // className='text-blue absolute my-1 -mx-8 bg-gray rounded-full'
        {...inputProps} />

    )
  }
)

export default Icon
