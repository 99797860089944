import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { Merge } from '../src/utils/types';
import { twMerge } from 'tailwind-merge';
import Footer from './layouts/Footer';

const DOMAIN = process.env.NEXT_PUBLIC_URL
export interface CorePageProps {
  title: string
  description: string
  className?: string
  footer?: boolean
  children: ReactNode
}

type PageProps = Merge<React.ComponentProps<'div'>, CorePageProps>

export default function Page({
  title,
  description,
  className,
  footer = false,
  children,
  ...passthroughProps
}: PageProps) {

  const router = useRouter();

  const pageTitle = title.concat(" | VisualOrc")
  let canonical = router.locale && router.locale !== router.defaultLocale
    ? DOMAIN.concat("/", router.locale, router.pathname)
    : DOMAIN.concat(router.pathname)
  let alternateDefault = DOMAIN.concat(router.pathname)
  let alternates = router.locales.map(l => {
    return {
      locale: l,
      url: router.locale == router.defaultLocale ? DOMAIN.concat(router.pathname) : DOMAIN.concat("/", router.locale, router.pathname)
    }
  })

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta property="og:locale" content={router.locale} />
        <link rel="canonical" href={canonical} />
        <link rel="alternate" hrefLang="x-default" href={alternateDefault} />
        {alternates.map((a, i) => <link key={i} rel="alternate" hrefLang={a.locale} href={a.url} />)}
      </Head>
      <div className='min-h-100vh flex flex-col'>
        <div
          className={twMerge(
            'w-full h-full pt-28 md:pt-36 pb-8 md:pb-16 px-4 md:px-6 flex-1',
            className
          )}
          {...passthroughProps}
        >
          {children}
        </div>
        {footer && <Footer />}
      </div>
    </>
  )
}
